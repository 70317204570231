<template>
  <div class="task_hall_user">
    <el-container>
      <el-header>
        <nav-header :titleType="titleType"></nav-header>
      </el-header>
      <el-container style="height: calc(100% - 60px);">
        <el-aside width="210px" class="aside_container">
          <nav-aside :menuList="menuList"></nav-aside>
        </el-aside>
        <el-main>
          <keep-alive include="UserTaskHall">
            <router-view />
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavHeader from '@/components/layout/nav-header/index.vue'
import NavAside from '@/components/layout/nav-aside/index.vue'
export default {
  components: {
    NavHeader,
    NavAside
  },
  data () {
    return {
      menuList: [
        { path: '/user/task-hall', title: '任务大厅', icon: 'iconfont icon-zhuye' },
        { path: '/user/task-manager', title: '任务管理', icon: 'iconfont icon-renwu' },
        { path: '/user/personal', title: '个人中心', icon: 'iconfont icon-geren' }
      ],
      titleType: 0
    }
  }
}
</script>

<style lang="scss">
.task_hall_user {
  height: 100%;
  .el-main {
    background-color: #F3F3F3;
  }
}
</style>
